import React from 'react'
import './style.less';
import {Row, Col} from "antd";
import BlogListItem from "../../components/BlogListItem";
import Link from "../../components/Link";
import {graphql, StaticQuery} from "gatsby";

class BlogGrid extends React.Component {

    render(){

        return (
            <div className="blog-grid">
                <Row>
                    <Col xs={{span: 24}}>
                        <h2 className="huge">Blog</h2>
                    </Col>
                </Row>
                <Row type="flex">
                    <Col xs={{span: 24}} md={{span:3}} >
                        <Link to="/blog/" className="arrow-link">Browse our blogs</Link>
                    </Col>
                    <Col xs={{span: 24}} md={{span:21}}>
                        <Row>
                            {this.props.posts && this.props.posts.map(({node}) => {
                                return (
                                    <Col xs={{span: 24}} md={{span:12}} lg={{span: 8}} key={node.id}>
                                        <BlogListItem
                                            post={node}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql` query featuredBlogListQuery {
            allWordpressPost(limit: 3, sort: { order: DESC, fields: [date] }){
              edges {
                node {
                  id
                  slug
                  link
                  wordpress_id
                  title
                  date(formatString: "MMMM YYYY")
                  acf {
                    featured_image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 350, maxHeight:215, quality: 90, cropFocus: CENTER) {
                            ... GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
           }
        `}
        render={(data) => (
            <BlogGrid posts={data.allWordpressPost.edges} />
        )}
    />
)
