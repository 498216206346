import React from 'react';
import { navigate } from "gatsby"

export default class ExternalContent extends React.Component {

    handleClick (event) {
        event.preventDefault()

        if (event.target.tagName === 'A') {
            let link = event.target.closest('a').getAttribute('href');
            // check if this is internal or external and handle appropriately
            if(link.charAt(0) === '/' || link.indexOf('https://www.storyman.co') !== -1){
                link.replace('https://www.storyman.co', '');
                // add a trailing slash
                if (link.substr(-1) !== '/') link += '/';
                navigate(link);
            } else {
                const win = window.open(link, '_blank');
                win.focus();
            }

        }
    }

    render() {
        return (
            <div onClick={event => this.handleClick(event)}>
                {this.props.children}
            </div>
        );
    }
}
