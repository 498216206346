import React from 'react'
import './style.less';
import { Row, Col } from 'antd';
import Img from "gatsby-image";

class ImageOverlap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            side: null,
        };
    }

    render(){

        return (
            <Row className={"image-overlap " + this.props.side}>
                <Col xs={{span: 24}} md={{span: 20, offset: 2}} >
                    <div className="image-wrap landscape">
                        { this.props.landscape != null ?
                            <Img fluid={this.props.landscape.localFile.childImageSharp.fluid} />
                            : null }
                    </div>
                    <div className="image-wrap portrait">
                        { this.props.portrait != null ?
                            <Img fluid={this.props.portrait.localFile.childImageSharp.fluid} />
                            : null }
                    </div>
                </Col>
            </Row>
        );
    }
}

export default ImageOverlap