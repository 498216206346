import React from 'react'
import './style.less';
import { Row, Col } from 'antd';
import ExternalContent from "../ExternalContent";

class Content extends React.Component {

    render(){

        return (
            <Row className="content">
                <Col xs={{span: 24}} md={{span: 20, offset: 2}} lg={{span: 16, offset: 4}}>
                    <ExternalContent>
                        <div dangerouslySetInnerHTML={{__html: this.props.content }} />
                    </ExternalContent>
                </Col>
            </Row>
        );
    }
}

export default Content