import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import BlogGrid from "../modules/BlogGrid/index";
import ImageOverlap from "../components/ImageOverlap/index";
import Content from "../components/Content/index";
import BlogIntro from "../components/BlogIntro/index";
import { graphql } from 'gatsby';

class Page extends Component {
    render() {

        const post = this.props.data.wordpressPost;
        const acfFields = this.props.data.wordpressPost.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = post.title;
        }

        let path = this.props.data.wordpressPost.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
            >
                <BlogIntro
                    title={post.title}
                    image={post.acf.featured_image}
                    author={post.author.name}
                    date={post.date}
                    lead={post.acf.lead_paragraph}
                    content={post.acf.content_blog_1}
                    location={this.props.location}
                />

                {post.acf.stacked_images_1.portrait !== null ?
                    <ImageOverlap
                        side={post.acf.stacked_images_1.align_images}
                        portrait={post.acf.stacked_images_1.portrait}
                        landscape={post.acf.stacked_images_1.landscape}
                    />
                : null }

                {post.acf.content_blog_2 !== '' ?
                    <Content
                        content={post.acf.content_blog_2}
                    />
                : null }
                <BlogGrid/>
            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      slug
      title
      author {
        id
        name
      }
      date(formatString: "MMMM YYYY")
      acf {
        featured_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 350, maxHeight:215, quality: 90, cropFocus: CENTER) {
                ... GatsbyImageSharpFluid
              }
            }
          }    
        }
        lead_paragraph
        content_blog_1
        stacked_images_1 {
          align_images
          portrait {
            localFile {
              childImageSharp {
                fluid(maxWidth: 440, maxHeight:560, quality: 90, cropFocus: CENTER) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          landscape {
            localFile {
              childImageSharp {
                fluid(maxWidth: 790, maxHeight:490, quality: 90, cropFocus: CENTER) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        content_blog_2
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        } 
      }
    }
  }
`

export default Page
